import React, {Component, useState} from "react";
import {Row, Col, Container, Modal, Button, ModalHeader, ModalBody, ModalFooter} from "reactstrap";
import {useSSR, withTranslation} from "react-i18next";
import imgVisionAndPurpose from "../assets/img/img-mindmap-vision-and-purpose.png";
import imgCommunity from "../assets/img/img-mindmap-community.png";
import imgCommunityModal from "../assets/img/img-community-modal.png";
import imgMerchantAndUsers from "../assets/img/img-mindmap-merchant-and-users.png";
import imgDAO from "../assets/img/img-mindmap-dao.png";
import imgDAOModal from "../assets/img/img-dao-modal.png";
import imgNFTS from "../assets/img/img-mindmap-nfts-new.png";
import imgMetaverse from "../assets/img/img-mindmap-metaverse.png";
import imgMetaverseModal from "../assets/img/img-metaverse-modal.png";
import imgConnectYourMind from "../assets/img/img-mindmap-connect-your-mind-new.png";
import imgConnectYourMindMobile from "../assets/img/img-mindmap-connect-your-mind-mobile.png";
import imgBGMobile from "../assets/img/img-mindmap-body-mobile-new-2.jpg";
import imgClose from "../assets/img/icon-mindmap-modal-close.png";
import {Service} from "../providers/Services";
import {toast, ToastContainer} from "react-toastify";

// Images
import ImgMindmapBanner from '../assets/img/mindmap-v2/img-mindmap-banner.png'
import Img01 from '../assets/img/mindmap-v2/img-01-vision-and-purpose.png'
import Img02 from '../assets/img/mindmap-v2/img-02-community.png'
import Img03 from '../assets/img/mindmap-v2/img-03-merchants.png'
import Img04 from '../assets/img/mindmap-v2/img-04-users.png'
import Img05 from '../assets/img/mindmap-v2/img-05-nfts.png'
import Img06 from '../assets/img/mindmap-v2/img-06-store-value.png'
import Img07 from '../assets/img/mindmap-v2/img-07-exchanges.png'
import Img08 from '../assets/img/mindmap-v2/img-08.png'

const toaststyle = {
  position: "top-right",
  autoClose: 2500,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: false,
  progress: undefined,
};

class MindmapBody2 extends Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
    this.state = {
      show: false,
      show2: false,
      modalImg: "img-mindmap-modal-default.png",
      modalTitle: "",
      modalNum: "",
      modalContent: [],
      modalClass: "",
      name: "",
      email: "",
      telephone: null,
      message: "",
    };
  }

  handleChange = (event) => {
    const {name, value} = event.target;
    this.setState({
      [name]: value,
    });
  };

  validateEmail = (email) => {
    return email.match(
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
  };

  containsOnlyNumbers(str) {
    return /^[0-9]+$/.test(str);
  }

  handleSubmit = (event) => {
    event.preventDefault();

    if (this.state.email === "") {
      toast.error("Email can't be empty !", toaststyle);
      return;
    } else if (!this.validateEmail(this.state.email)) {
      toast.error("Email is invalid, please enter a valid email!", toaststyle);
    } else if (this.state.name === "") {
      toast.error("Name can't be empty !", toaststyle);
      return;
    } else if (this.state.message === "") {
      toast.error("Please tell us your thought!", toaststyle);
      return;
    } else if (this.state.telephone && !this.containsOnlyNumbers(this.state.telephone)) {
      toast.error("Phone number is invalid, please enter a valid number!", toaststyle);
      return;
    } else {
      const payload = {
        email: this.state.email,
        name: this.state.name,
        phone: +this.state.telephone,
        message: this.state.message,
        type: 0,
      };
      Service.mindmapConnect(payload)
        .then((res) => {
          this.setState({
            email: "",
            name: "",
            message: "",
            telephone: null,
          });
          toast.success(
            "Thank you for reaching out to us. We will get back to you as soon as possible!",
            toaststyle
          );
        })
        .catch((error) => {
          if (error.status === 400) {
            toast.error("Email is invalid, please enter a valid email!", toaststyle);
          } else {
            toast.error(
              error && error.response ? error.response.data.message : "Some error occurred!",
              toaststyle
            );
          }
        });
    }
  };

  handleClose = () => {
    this.setState({show: false});
  };
  handleClose2 = () => {
    this.setState({show2: false});
  };
  handleShow = (obj) => {
    this.setState({
      modalTitle: obj.title,
      modalImg: obj.img,
      modalNum: obj.number,
      modalContent: obj.content,
      modalClass: obj.id,
      show: true,
    });
  };
  handleShow2 = () => {
    this.setState({show2: true});
  };

  componentDidMount = () => {
    const path = window.location.pathname;

    if (path == "/en/mindmap/connect") {
      this.setState({show2: true});
    } else {
      this.setState({show2: false});
    }
  };
  render() {
    const {t} = this.props;
    return (
      <div className='v2-mindmap'>
        <ToastContainer
            position="top-right"
            autoClose={2000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable={false}
            pauseOnHover
          />

        {/* Section 1 */}
        <section className='v2-mindmap-1'></section>
        <section className="v2-mindmap-2">
          <Container>
            <Row>
              <Col sm={7}>
                <h1 className='v2-font-34 v2-m-font-28 text-center v2-dark-text'>{t("mindmap.mindmap")}</h1>
                <p className='v2-font-16 text-center v2-dark-text-2 v2-m-font-15'>{t("mindmap.we")}</p>
              </Col>

              <Col sm={5}>
                <div className='img-wrap v2-px-2 v2-tp-mt-2'>
                    <img className="v2-tp-mw-50 v2-m-mw-90" src={ImgMindmapBanner} />
                </div>
              </Col>
            </Row>
            <Row className="mindmap-body-row-2">
              <Col>
                <div className="mindmap-body-lists v2-mindmap-body-lists">
                  {/* Vision and Purpose */}
                  <div
                    className="mindmap-body-list mindmap-body-list-1"
                    onClick={() =>
                      this.handleShow({
                        id: "vision",
                        img: Img01,
                        title: t("mindmap.vision"),
                        number: "01",
                        content: [t("mindmap.vision1"), t("mindmap.vision2")],
                      })
                    }
                  >
                    <div className="mindmap-body-list-img">
                      <img src={Img01} />
                    </div>
                    <p className="mindmap-body-list-num">01</p>
                    <p className="mindmap-body-list-title">
                      {t("mindmap.vision")} <i class="mindmap-body-arrow fas fa-arrow-right"></i>
                    </p>
                  </div>
                  {/* Community */}
                  <div
                    className="mindmap-body-list mindmap-body-list-2"
                    onClick={() =>
                      this.handleShow({
                        id: "community",
                        img: Img02,
                        title: t("mindmap.community"),
                        number: "02",
                        content: [t("mindmap.community1"), t("mindmap.community2")],
                      })
                    }
                  >
                    <div className="mindmap-body-list-img">
                      <img src={Img02} />
                    </div>
                    <p className="mindmap-body-list-num">02</p>
                    <p className="mindmap-body-list-title">
                      {t("mindmap.community")} <i class="mindmap-body-arrow fas fa-arrow-right"></i>
                    </p>
                  </div>
                  {/* Merchant and Users */}
                  <div
                    className="mindmap-body-list mindmap-body-list-3"
                    onClick={() =>
                      this.handleShow({
                        id: "merchant",
                        img: Img03,
                        title: t("mindmap.merchants"),
                        number: "03",
                        content: [t("mindmap.merchant1"), t("mindmap.merchant2")],
                      })
                    }
                  >
                    <div className="mindmap-body-list-img">
                      <img src={Img03} />
                    </div>
                    <p className="mindmap-body-list-num">03</p>
                    <p className="mindmap-body-list-title">
                      {t("mindmap.merchants")} <i class="mindmap-body-arrow fas fa-arrow-right"></i>
                    </p>
                  </div>
                  {/* DAO */}
                  <div
                    className="mindmap-body-list mindmap-body-list-4"
                    onClick={() =>
                      this.handleShow({
                        id: "dao",
                        img: Img04,
                        title: t("mindmap.users"),
                        number: "04",
                        content: [t("mindmap.dao1"), t("mindmap.dao2")],
                      })
                    }
                  >
                    <div className="mindmap-body-list-img">
                      <img src={Img04} />
                    </div>
                    <p className="mindmap-body-list-num">04</p>
                    <p className="mindmap-body-list-title">
                      {t("mindmap.users")} <i class="mindmap-body-arrow fas fa-arrow-right"></i>
                    </p>
                  </div>
                  {/* NFTS */}
                  <div
                    className="mindmap-body-list mindmap-body-list-5"
                    onClick={() =>
                      this.handleShow({
                        id: "nfts",
                        img: Img05,
                        title: t("mindmap.nfts"),
                        number: "05",
                        content: [t("mindmap.nfts1"), t("mindmap.nfts2")],
                      })
                    }
                  >
                    <div className="mindmap-body-list-img">
                      <img src={Img05} />
                    </div>
                    <p className="mindmap-body-list-num">05</p>
                    <p className="mindmap-body-list-title">
                      {t("mindmap.nfts")} <i class="mindmap-body-arrow fas fa-arrow-right"></i>
                    </p>
                  </div>
                  {/* METAVERSE */}
                  <div
                    className="mindmap-body-list mindmap-body-list-6"
                    onClick={() =>
                      this.handleShow({
                        id: "storeOfValue",
                        img: Img06,
                        title: t("mindmap.storeOfValue"),
                        number: "06",
                        content: [t("mindmap.metaverse1"), t("mindmap.metaverse2")],
                      })
                    }
                  >
                    <div className="mindmap-body-list-img">
                      <img src={Img06} />
                    </div>
                    <p className="mindmap-body-list-num">06</p>
                    <p className="mindmap-body-list-title">
                      {t("mindmap.storeOfValue")} <i class="mindmap-body-arrow fas fa-arrow-right"></i>
                    </p>
                  </div>
                  
                  <div
                    className="mindmap-body-list mindmap-body-list-8"
                    onClick={() =>
                      this.handleShow({
                        id: "metaverse",
                        img: Img06,
                        title: t("mindmap.exchanges"),
                        number: "07",
                        content: [t("mindmap.metaverse1"), t("mindmap.metaverse2")],
                      })
                    }
                  >
                    <div className="mindmap-body-list-img">
                      <img src={Img07} />
                    </div>
                    <p className="mindmap-body-list-num">07</p>
                    <p className="mindmap-body-list-title">
                      {t("mindmap.exchanges")} <i class="mindmap-body-arrow fas fa-arrow-right"></i>
                    </p>
                  </div>

                  {/* CONNECT YOUR MIND */}
                  <div
                    className="mindmap-body-list mindmap-body-list-7"
                    onClick={() => this.handleShow2()}
                  >
                    <div className="mindmap-body-list-img">
                      <img className="desktop" src={Img08} />
                      <img className="mobile" src={Img08} />
                    </div>
                    <p className="mindmap-body-list-num">08</p>
                    <p className="mindmap-body-list-title">
                      {t("mindmap.connect")} <i class="mindmap-body-arrow fas fa-arrow-right"></i>
                    </p>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        {/* MODAL CONTENT */}
        <Modal className={"mindmap-modal v2-mindmap-modal" + " " + this.state.modalClass} isOpen={this.state.show}>
          <ModalHeader>
            <a className="mindmap-modal-close" onClick={this.handleClose}>
              <img src={imgClose} alt="Close" />
            </a>
          </ModalHeader>
          <ModalBody>
            <div className="mindmap-modal-body-wrap">
              <div className="mindmap-modal-body-col mindmap-modal-body-col-left">
                <div className="mindmap-modal-body-col-inner">
                  <div className="mindmap-modal-body-col-img">
                    <img src={this.state.modalImg} />
                  </div>
                  <div className="mindmap-modal-body-col-title">{this.state.modalTitle}</div>
                  <div className="mindmap-modal-body-col-num">{this.state.modalNum}</div>
                </div>
              </div>
              <div className="mindmap-modal-body-col mindmap-modal-body-col-right">
                <div className="mindmap-modal-body-col-inner">
                  <div className="mindmap-modal-body-col-text">
                    {this.state.modalContent.map((content) => (
                      <p>{content}</p>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </ModalBody>
        </Modal>

        {/* 07: CONNECT YOUR MIND MODAL */}
        <Modal
          className={"mindmap-modal" + " " + "mindmap-modal-connect"}
          isOpen={this.state.show2}
        >
          <ModalHeader>
            <a className="mindmap-modal-close" onClick={this.handleClose2}>
              <img src={imgClose} alt="Close" />
            </a>
          </ModalHeader>
          <ModalBody>
            <form onSubmit={this.handleSubmit} className="mindmap-modal-form">
              <div className="mindmap-modal-body-wrap">
                <div className="mindmap-modal-body-col mindmap-modal-body-col-left">
                  <div className="mindmap-modal-body-col-inner">
                    <div className="mindmap-modal-body-col-num">07</div>
                    <div className="mindmap-modal-body-col-title">{t("mindmap.connect")}</div>
                    <div className="mindmap-form-group">
                      <input
                        className="mindmap-form-group-input"
                        type="text"
                        name="name"
                        placeholder="Name"
                        value={this.state.name}
                        onChange={this.handleChange}
                      />
                    </div>
                    <div className="mindmap-form-group">
                      <input
                        className="mindmap-form-group-input"
                        type="email"
                        name="email"
                        placeholder="Email"
                        value={this.state.email}
                        onChange={this.handleChange}
                      />
                    </div>
                    <div className="mindmap-form-group">
                      <input
                        className="mindmap-form-group-input"
                        type="text"
                        name="telephone"
                        placeholder="Tel (not required)"
                        value={this.state.telephone}
                        onChange={this.handleChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="mindmap-modal-body-col mindmap-modal-body-col-right">
                  <div className="mindmap-modal-body-col-inner">
                    <div className="mindmap-form-group">
                      <textarea
                        className="mindmap-form-group-input mindmap-form-group-input-textarea"
                        placeholder="Tell us your thoughts"
                        name="message"
                        value={this.state.message}
                        onChange={this.handleChange}
                      ></textarea>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mindmap-modal-submit-wrap">
                <button className="mindmap-modal-submit-btn" type="submit" name="submit">
                  SUBMIT MESSAGE
                </button>
              </div>
            </form>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}
export default withTranslation()(MindmapBody2);