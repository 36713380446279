import React, { Component } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

// SVGS
import { ReactComponent as IconPopupClose } from '../../assets/img/premium-wallet/svgs/icon-popup-close.svg';

import ImgOvato from '../../assets/img/logo-ovato-popup.png'

class RelaunchingPopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      
    };
  }

  render() {

    const { t, toggle, modal} = this.props;

    return (
      <div>
        <Modal isOpen={modal} toggle={toggle} backdrop={true} className={'premium-modal relaunch-modal'}>
          
          <ModalBody>

            <div className='premium-modal-close'><a href='#' onClick={toggle}><IconPopupClose /></a></div>

            <div className='img-wrap text-center relaunch-modal-img'>
                <img src={ImgOvato} />
            </div>

            <p className='poppins v2-font-23 bold dark-text-5 text-center lh-1-2 mb-0'>We are updating the apps and</p>

            <p className='v2-mt-0-5 poppins v2-font-47 v2-m-font-32 bold dark-text-5 text-center text-uppercase lh-1-1 mb-0'>re-launching <br/> in April 2025 </p>

            <div className='v2-mt-2 text-center btn-wrap'>
                <a onClick={(e) => toggle(e)} href='#' className='custom-new-btn rm-btn'>Got It</a>
            </div>
          </ModalBody>
          
        </Modal>
      </div>
    );
  }
}

export default RelaunchingPopup;
